.cv-content {
  h1, h2, h3, h4, h5 {
    font-weight: 700;
    text-transform: uppercase;
  }

  p {
    letter-spacing: 0.75px;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: 300;
  }
}
