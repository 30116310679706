.form-control {
  border: transparent;
  border-radius: 0;
  border-bottom: 3px solid #dee2e6;
  letter-spacing: 1.06px;
}

.form-control:focus {
  border-color: transparent;
  outline: none;
  box-shadow: none;
  border-bottom: 3px solid #dee2e6;
}

.submit-btn {
  padding-top: 49px;
}

.submit-btn button {
  border-radius: 28px;
  width: 240px;
  font-size: 21px;
  font-weight: 600;
  background-color: #616569;
  color: #ffffff;
  text-transform: uppercase;
}

.submit-btn button:focus {
  box-shadow: none;
}
