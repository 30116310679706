.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.navbar.mobile {
  display: none;
  .navbar-logo img {
    width: 85px;
  }
}

.navbar-toggler {
  padding: 0;
  margin: 0;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #000000;
  margin: 6px 0;
  transition: 0.5s;
}

.bar1.change {
  transform: rotate(-45deg) translate(-10px, 10px);
}

.bar2.change {
  opacity: 0;
}

.bar3.change {
  transform: rotate(45deg) translate(-5px, -5px);
}

.navbar-logo {
  position: absolute;
  top: 20%;
  left: 3%;

  img {
    width: 100px;
  }
}

.logo-black {
  display: none;
}

.nav-item,
.nav-item > .nav-link {
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.75px;
}

.anime-border-dark {
  display: inline-block;
  text-decoration: none;
}

.anime-border-dark,
.current-link {
  color: #000000;
}

.anime-border-dark::after,
.current-link::after {
  content: "";
  display: block;
  height: 3px;
  transition: width 0.3s;
}

.anime-border-dark::after {
  width: 0;
}

.current-link::after {
  width: 100%;
}

.anime-border-dark::after,
.current-link::after {
  background: #000000;
}

.anime-border-dark:hover::after {
  width: 100%;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
