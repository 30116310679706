.modal-content {
  border: none;
  border-radius: 0;
}

.modal-header {
  align-items: center;
}

.close {
  color: #343a40 !important;
  opacity: 1;
}

.ekko-lightbox-nav-overlay a {
  font-size: 4rem;
}

.modal-backdrop.show {
  opacity: 0.9 !important;
}

.modal-backdrop {
  background-color: #ccc !important;
}

.grid-sizer,
.grid-item {
  width: 100%;
}

.grid-item {
  margin-bottom: 35px;
  img {
    width: 100%;
  }
}
