// Bootstrap
@import "~bootstrap/scss/bootstrap";

// FilePond imports
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
// @import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

//@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");

@import "navbar";
@import "admin";

@import "home/header";
@import "home/portfolio";
@import "home/contact-me";

@import "gallery/index";
@import "gallery/admin";
@import "news/index";
@import "news/admin";
@import "cv/index";

hr {
  border: 3px solid #dee2e6;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
.form-control {
  font-family: "Montserrat", sans-serif;
}

button {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
}

.underline {
  width: 25%;
  height: 3px;
  background-color: #dee2e6;
}

.heading {
  margin-top: 125px;
  margin-bottom: 50px;
}

.main-header {
  letter-spacing: 2.5px;
  font-weight: 300;
}

.main-text {
  letter-spacing: 0.75px;
  margin-bottom: 0.5rem;
}

.footer-text {
  letter-spacing: 0.75px;
  font-size: 12px;
  font-weight: 300;
}

#button-up {
  display: inline-block;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

#button-up:hover {
  cursor: pointer;
}

#button-up::after {
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 50px;
  color: #fff;
}

#button-up.show {
  opacity: 1;
  visibility: visible;
}

.button-icon {
  font-size: 40px;
  padding-top: 0.1875rem;
}

.instagram-icon,
.facebook-icon {
  font-size: 2rem;
  color: #212529;
}

.footer-index {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.instagram-icon:hover,
.instagram-icon:active,
.facebook-icon:hover,
.instagram-icon:active {
  color: #000;
}

.maintenance-page {
  height: 100dvh;
}

@import "media-queries";
