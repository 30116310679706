@media (max-width: 1200px) {
  .category {
    max-width: 296px;
  }
}

@media (max-width: 992px) {
  .navbar {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .heading {
    margin-top: 100px;
    margin-bottom: 25px;
  }

  .navbar-not-index {
    background-color: white;
  }

  .navbar.desktop {
    display: none;
  }

  .navbar.mobile {
    display: flex;
  }

  .main-header {
    font-size: 28px;
  }

  .navbar-logo {
    position: relative;
    top: 0;
    left: 0;
  }

  .nav-item > .nav-link,
  .menu-divider {
    color: #000000;
  }

  .menu-divider {
    display: none;
  }

  .navbar-nav {
    text-align: center;
  }

  .underline {
    width: 20%;
  }
  #portfolio .row {
    justify-content: center;
  }

  .gallery-images,
  .news-images {
    columns: 3;
  }
}

@media (max-width: 768px) {
  .category {
    max-width: 246px;
  }
  .category-title-overlay h4 {
    font-size: 1.25rem;
  }

  .lead {
    font-size: 1rem;
  }

  .cv-content {
    p {
      font-size: 1rem;
    }
  }
}

@media (max-width: 575px) {
  .underline {
    width: 10%;
  }

  .ekko-lightbox-nav-overlay a {
    font-size: 3em !important;
  }

  #button-up {
    right: 0 !important;
  }

  .gallery-images,
  .news-images {
    columns: 2;
  }
}

@media (max-width: 425px) {
  .category-title-overlay h2 {
    font-size: 1.75rem;
  }
}

@media (max-width: 375px) {
  .main-header {
    font-size: 20px;
  }
  .lead {
    font-size: 0.875rem;
  }
  .cv-content {
    p {
      font-size: 0.875rem;
    }
  }
}

@media (min-width: 576px) {
  .grid-sizer,
  .grid-item {
    width: 220px;
  }
}

@media (min-width: 768px) {
  .grid-sizer,
  .grid-item {
    width: 310px;
  }
}

@media (min-width: 992px) {
  .grid-sizer,
  .grid-item {
    width: 445px;
  }
}

@media (min-width: 1200px) {
  .grid-sizer,
  .grid-item {
    width: 345px;
  }
}
