.news-item-content {
  p:not(.small) {
    letter-spacing: .75px;
    margin-bottom: .5rem;
    font-size: 1.25rem;
    font-weight: 300;
  }

  h4 {
    font-weight: bold;
  }
}
