.category {
  max-width: 356px;
  margin: 0.75rem;
  position: relative;
}

.category a:hover,
.category a:active {
  text-decoration: none;
}

.category:hover,
.category:active {
  cursor: pointer;

  & .category-title-overlay {
    height: 100%;
    transition: all 0.4s;
  }
}

.category-title-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: #dee2e6ad;
  transition: all 0.2s;
}

.list-inline-item:not(:last-child) {
  margin-right: 0;
}

.modal-body {
  padding: 0;
}
